import React from "react";
import logoh from "./img/logo_verenso_h.png";
import logov from "./img/logo_verenso_v.png";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <picture>
          <source media="(max-width: 768px)" srcSet={logov} />
          <source media="(max-width: 769px)" srcSet={logoh} />
          <img src={logoh} className="App-logo" alt="logo" />
        </picture>
      </header>
    </div>
  );
}

export default App;
